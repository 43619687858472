
import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import AddProductListSalesEnquiry from "@/components/modals/forms/AddProductListSalesEnquiry.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import { mask } from "vue-the-mask";
import { ActionsFi } from "@/store/enums/StoreFiEnums";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "customers-listing",
  directives: { mask },
  components: {
    ExportCustomerModal,
    AddProductListSalesEnquiry,
  },

  setup() {
    const store = useStore();
    const manufacturer_data = ref([]);
    const category_data = ref<Array<Category>>([]);
    const standardJson = ref<any>([]);
    const cat_attr_count = ref(0);
    const structure_data = ref<any>([]);
    const table_data = ref<any>([]);
    const standard_data = ref([]);
    const my_data_id = ref(0);

    interface Category {
      category_id: string;
      category_name: string;
      is_next_child_present: number;
    }

    const dialogSelectProductTableVisible = ref(false);

    const myData = ref<MyDataStructure[]>([]);

    onMounted(async () => {
      setCurrentPageBreadcrumbs("Create Purchase Requisition", []);
    });

    const formRef = ref<null | HTMLFormElement>(null);
    const formAddProductRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const router = useRouter();

    getCategoryData(0, "");

    function deleteRow(row) {
      var index = myData.value
        .map((deteRow) => {
          return deteRow.id;
        })
        .indexOf(row?.id);
      myData.value.splice(index, 1);
      if (myData.value.length === 0) {
        my_data_id.value = 0;
      }
    }

    const formData = ref({
      uom_select: "",
      sjvd_date: new Date(),
      pr_remarks: "",
      attribute_name: [],
      standard_select: {},
      standard_attr_select: "",
    });

    interface Tree {
      category_name: string;
      category_id: string;
      uom_name: string;
      uom_id: number;
    }

    const handleCheckChange = (
      data: Tree,
      checked: boolean,
      indeterminate: boolean
    ) => {
      resetProductForm(true);
      formAddProductRef?.value?.resetFields();
      formAddProductRef?.value?.clearValidate();

      formProductData.value["standard_select"] = "";
      formProductData.value["standard_attr_select"] = "";
      manufacturer_data.value = [];
      formProductData.value["category_select"] = data.category_id;
      formProductData.value["category_name"] = data.category_name;
      formProductData.value["uom_select"] = {
        uom_id: data.uom_id,
        uom_name: data.uom_name,
      };

      getCompanyList(), getStandardGradeJson(), getAttributeStructure();
      console.log(data, checked, indeterminate);
    };

    const rangeValidator = async (
      value,
      rowIndex,
      childIndex,
      min,
      max,
      fileld
    ) => {
      debugger;
      let val = parseFloat(value);
      let minNum = parseFloat(min);
      let maxNum = parseFloat(max);
      let input: any = document.getElementsByClassName(fileld)[0].children[0];
      let warning: any = document.getElementById(fileld);
      warning.innerHTML = "";
      input.style.borderColor = "";
      if ((!/^\d*\.?\d*$/.test(value))||
          (value < minNum || val > maxNum || Number.isNaN(val))) {
        formProductData.value.attribute_name[fileld] = "";
        warning.innerHTML = "Please enter value between " + min + "-" + max;
        input.style.borderColor = "red";
      }
    };

    const formProductData = ref({
      manufacturers_select: [],
      category_select: "",
      category_name: "",
      qty: "",
      remark: "",
      uom_select: {},
      attribute_name: [],
      standard_select: {},
      standard_attr_select: {},
    }) as any;

    interface MyDataStructure {
      id: number;
      mfg_company_json: Record<string, string | number>[];
      category_id: string;
      brand_ids: Record<string, string | number>[];
      required_qty: string;
      uom: Record<string, unknown>;
      remarks: string;
      specifications: Record<string, string | number>[];
      product_name: string;
      standard_id: number;
      standard_attribute_id: any;
      category_attribute_json: Record<string, string | number>[];
      product_descriptions: Record<any, any>;
    }

    const rules = ref({
      uom_select: [
        {
          required: true,
          message: "UOM is required",
          trigger: "change",
        },
      ],
      sjvd_date: [
        {
          required: true,
          message: "Requsition Date is required",
          trigger: "change",
        },
      ],
    });

    const productRules = ref({
      category_select: [
        {
          required: true,
          message: "Category is required",
          trigger: "change",
        },
      ],
      // manufacturers_select: [
      //   {
      //     required: true,
      //     message: "Manufacturer is required",
      //     trigger: "change",
      //   },
      // ],
      standard_select: [
        {
          required: true,
          type: "object",
          message: "Standard is required",
          trigger: "change",
        },
      ],
      standard_attr_select: [
        {
          required: true,
          message: "Grade is required",
          trigger: "change",
        },
      ],
      qty: [
        {
          required: true,
          message: "Quantity is required",
          trigger: "change",
        },
      ],
    });

    const setCompanyData = async (data) => {
      debugger;
      var user = JSON.parse(JwtService.getToken());
      let product_list = myData.value.map((item) => {
        return {
          category_id: item.category_id,
          mfg_company_json: item.mfg_company_json,
          standard_id: item.standard_id,
          standard_attribute_generic_id: item.standard_attribute_id,
          category_attribute_json: item.category_attribute_json,
          pr_quantity: item.required_qty,
          uom_json: item.uom,
          pr_remark: item.remarks,
        };
      });

      if (product_list.length <= 0) {
        console.log("validation failed");
        Swal.fire({
          text: "Please Add At Least One Product.",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        loading.value = false;
        return;
      }

      const db_data = {
        pr_date: formData.value.sjvd_date.toLocaleDateString("en-CA"),
        pr_remarks: formData.value.pr_remarks,
        pr_detail: product_list,
        own_user_id: user.user_id,
        user_id: user.user_id,
      };
      console.log(JSON.stringify(db_data));
      try {
        await store
          .dispatch(ActionsFi.CUST_CREATE_PURCHASE_REQUISITION, db_data)

          .then(({ data, is_error, status }) => {
            debugger;
            loading.value = false;
            console.log(data);
            if ((!is_error && status && status === 200) || status === 201) {
              setTimeout(() => {
                loading.value = false;
                Swal.fire({
                html: ""+
                      data?.pr_no+
                      "<br>Purchase requisition created successfully!",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  router.push({ name: "apps-purchase-rquition-listing" });
                });
              }, 2000);
            } else {
              setTimeout(() => {
                loading.value = false;
                Swal.fire({
                  text: data?.errorMessage ? data?.errorMessage : "Eror",
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then();
              }, 2000);
            }
          })
          .catch(({ response }) => {
            loading.value = false;
            console.log(response);
            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          });
      } catch (e) {
        loading.value = false;
        console.log(e);
      }
    };

    const submit = async () => {
      loading.value = true;
      if (
        formData.value["incoterms_select"] != 1 &&
        formData.value["incoterms_select"] != null
      ) {
        rules.value = {
          uom_select: [
            {
              required: true,
              message: "UOM is required",
              trigger: "change",
            },
          ],
          sjvd_date: [
            {
              required: true,
              message: "Requsition Date is required",
              trigger: "change",
            },
          ],
        };
      }
      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setCompanyData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    const submitProduct = async () => {
      loading.value = true;
      if (!formAddProductRef.value) {
        loading.value = false;
        return;
      }

      formAddProductRef.value.validate(async (valid) => {
        if (valid) {
          addProduct();
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    async function getCategoryData(id, type) {
      await store
        .dispatch(Actions.CUST_GET_CATEGORY_PARENT_LIST)
        .then(({ data }) => {
          debugger;
          category_data.value = data ? data : [];
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    function Required() {
      return { color: "red" };
    }

    const resetForm = () => {
      formData.value = {
        uom_select: "",
        sjvd_date: new Date(),
        pr_remarks: "",
        attribute_name: [],
        standard_select: {},
        standard_attr_select: "",
      };
    };

    function onClick() {
      rules.value.uom_select[0].required = false;
      rules.value.sjvd_date[0].required = false;
      my_data_id.value = 0;
      myData.value = [];
      resetForm();
    }

    const getStandardGradeJson = async () => {
      debugger;
      var values = {
        category_id: formProductData.value["category_select"],
        company_id: 0,
        company_branch_id: 0,
        brand_id: 0,
      };
      loading.value = true;
      await store
        .dispatch(Actions.CUST_GET_PRODUCT_STANDARD_JSON_DATA, values)
        .then((data) => {
          debugger;
          if (data.status === 200) standardJson.value = data.data;
          else standardJson.value = [];
          loading.value = false;
        })
        .catch(({ e }) => {
          console.log(e);
        });
    };

    async function getAttributeStructure() {
      debugger;

      var values = {
        category_id: formProductData.value["category_select"],
        company_id: 0,
        company_branch_id: 0,
        brand_id: 0,
      };
      loading.value = true;
      cat_attr_count.value = 0;
      await store
        .dispatch(
          Actions.CUST_GET_PRODUCT_ATTRIBUTE_STRUCTURE_BY_CATEGORY_ID,
          values
        )
        .then(({ data }) => {
          debugger;
          loading.value = false;

          let formStructure = data.map((item) => {
            if (item.input_value_required_yn === 0) {
              let value = {};
              value["parentLabel"] = item.attribute_label;
              value["input_value_required_yn"] = item.input_value_required_yn;
              let childAttr = item.sub_attributes.map((child) => {
                let subAttr = {
                  input_value_type: child.input_value_type,
                  attribute_label: child.attribute_label,
                  category_attribute_setting_id:
                    child.category_attribute_setting_id,
                  category_attribute_value: "",
                  min_value: child.min_value,
                  max_value: child.max_value,
                  input_value_required_yn: child.input_value_required_yn,
                  attribute_values: child.attribute_values,
                  attribute_name: child.attribute_label.toLowerCase(),
                };
                if (child.input_value_type === "RANGE") {
                  subAttr["range"] = {
                    min: parseFloat(child.min_value),
                    max: parseFloat(child.max_value),
                  };
                }
                cat_attr_count.value++;
                return subAttr;
              });
              value["subAttr"] = childAttr;
              return value;
            } else {
              let value = {
                input_value_type: item.input_value_type,
                attribute_label: item.attribute_label,
                category_attribute_setting_id:
                  item.category_attribute_setting_id,
                category_attribute_value: "",
                min_value: 0,
                max_value: 0,
                input_value_required_yn: item.input_value_required_yn,
                attribute_values: item.attribute_values,
                attribute_name: item.attribute_label.toLowerCase(),
              };
              value["parentLabel"] = "";
              if (item.input_value_type === "RANGE") {
                // value["range"]=  {
                  value.min_value = parseFloat(item.min_value);
                  value.max_value = parseFloat(item.max_value);
                // };
              }
              cat_attr_count.value++;
              return value;
            }
          });
          structure_data.value = formStructure;
          console.log('structure_data',structure_data)
          let rule_stuctrue = {};
          formStructure.map((item) => {
            if (item.subAttr) {
              item.subAttr.map((attr) => {
                let label = attr.attribute_name;
                rule_stuctrue[label] = [
                  {
                    required: true,
                    message: attr.attribute_label + "  is required",
                    trigger: "change",
                  },
                ];
              });
            } else {
              let label = item.attribute_name;
              rule_stuctrue[label] = [
                {
                  required: true,
                  message: item.attribute_label + "  is required",
                  trigger: "change",
                },
              ];
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const resetProductForm = async (isCategoryChange = false) => {
      formProductData.value.manufacturers_select = '';
      formProductData.value.category_select = "";
      formProductData.value.category_name = "";
      formProductData.value.qty = "";
      formProductData.value.remark = "";
      formProductData.value.uom_select = {};
      formProductData.value.attribute_name = [];
      formProductData.value.standard_select = "";
      formProductData.value.standard_attr_select = {};
      manufacturer_data.value = [];
      standardJson.value = [];
      structure_data.value = [];
      table_data.value = [];
      standard_data.value = [];
      if (!isCategoryChange) {
        dialogSelectProductTableVisible.value = false;
      }
    };

    const addProduct = () => {
      debugger;
      let spec: any = [];
      let product_description = {} as any;

      let mfg_company_json = formProductData.value.manufacturers_select.map(
        (item: any) => {
          return {
            company_id: item.company_id,
            company_name: item.manufacturer_name,
          };
        }
      );

      let category_attributes = formProductData.value.attribute_name;
      let selected_attr_count = Object.keys(category_attributes).length;
      let attribute_emapty = false;
      for (let key in category_attributes) {
        let value: any = category_attributes[key];
        if (typeof value === "string" && !value.trim()) {
          attribute_emapty = true;
          break;
        }
      }

      if (selected_attr_count < cat_attr_count.value || attribute_emapty) {
        Swal.fire({
          text: "Plese fill all the category attributes !",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        loading.value = false;
        return;
      }

      let category_attributes_array = [] as any;
      for (let key in category_attributes) {
        let keys = key.split(",");
        let category_attribute_setting_id = parseInt(keys[1]);
        let attribute_value: any = category_attributes[key];
        let new_attribute_obj = {};
        if (
          typeof attribute_value === "object" &&
          Object.keys(attribute_value).includes("category_attribute_generic_id")
        ) {
          new_attribute_obj = Object.assign(
            { category_attribute_setting_id: category_attribute_setting_id },
            attribute_value
          );
          category_attributes_array.push(new_attribute_obj);
          let keyName =
            keys[0].toString().charAt(0).toUpperCase() +
            keys[0].toString().slice(1);
          product_description[keyName] =
            attribute_value.category_attribute_value;
        } else {
          new_attribute_obj["category_attribute_setting_id"] =
            category_attribute_setting_id;
          new_attribute_obj["category_attribute_generic_id"] = 0;
          new_attribute_obj["category_attribute_value"] = attribute_value;
          category_attributes_array.push(new_attribute_obj);
          for (let item of structure_data.value) {
            if (item["parentLabel"] && Object.keys(item["subAttr"].length)) {
              product_description[item["parentLabel"]] =
                product_description[item["parentLabel"]] &&
                Object.keys(product_description[item["parentLabel"]]).length !==
                  0
                  ? Object.assign({}, product_description[item["parentLabel"]])
                  : {};
              for (let data of item["subAttr"]) {
                if (
                  data["category_attribute_setting_id"] ===
                  category_attribute_setting_id
                ) {
                  product_description[item["parentLabel"]][
                    data["attribute_label"]
                  ] = attribute_value;
                }
              }
            }else if(item['parentLabel'] == "" && 
            item['category_attribute_setting_id'] == category_attribute_setting_id){
              product_description[item["attribute_name"]] = attribute_value;
            }
          }
        }
      }
      if (formProductData.value.standard_select["standard_no"]) {
        product_description["standard_name"] = formProductData.value
          .standard_select["standard_no"]
          ? formProductData.value.standard_select["standard_no"]
          : "";
      }
      if (
        formProductData.value.standard_attr_select["standard_attribute_value"]
      ) {
        product_description["grade"] = formProductData.value
          .standard_attr_select["standard_attribute_value"]
          ? formProductData.value.standard_attr_select[
              "standard_attribute_value"
            ]
          : "";
      }
      my_data_id.value++;
      myData.value.push({
        id: my_data_id.value,
        category_id: formProductData.value["sub_category_select"]
          ? formProductData.value["sub_category_select"]
          : formProductData.value["category_select"],
        brand_ids: formProductData.value["brand_select"],
        required_qty: formProductData.value["qty"],
        uom: formProductData.value["uom_select"],
        remarks: formProductData.value["remark"],
        specifications: spec,
        product_name: formProductData.value["category_name"],
        mfg_company_json: mfg_company_json,
        standard_id: formProductData.value.standard_select["standard_id"]
          ? formProductData.value.standard_select["standard_id"]
          : 0,
        standard_attribute_id: formProductData.value.standard_attr_select[
          "standard_attribute_generic_id"
        ]
          ? formProductData.value.standard_attr_select[
              "standard_attribute_generic_id"
            ]
          : 0,
        category_attribute_json: category_attributes_array,
        product_descriptions: product_description,
      });
      loading.value = false;
      resetProductForm();
    };

    const openDilogue = () => {
      dialogSelectProductTableVisible.value = true;
    };

    const getCompanyList = async () => {
      let values = {
        category_id: formProductData?.value?.category_select,
        page: 1,
        records_per_page: 1000,
      };
      await store
        .dispatch(Actions.CUST_GET_CATEGORY_MANUFACTURER_LIST_DATA, values)
        .then(({ data }) => {
          debugger;
          manufacturer_data.value = data.result_list;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    const validateInput = (fieldName) => {
      if (!/^\d*\.?\d*$/.test(formProductData.value[fieldName]) || 
          formProductData.value[fieldName] == 0
        ){
        formProductData.value[fieldName] = formProductData.value[fieldName].slice(0, -1);
        return
      }
    }

    return {
      formData,
      formProductData,
      rules,
      productRules,
      submit,
      submitProduct,
      formRef,
      category_data,
      formAddProductRef,
      loading,
      addProduct,
      myData,
      deleteRow,
      Required,
      openDilogue,
      resetProductForm,
      dialogSelectProductTableVisible,
      onClick,
      manufacturer_data,
      standardJson,
      structure_data,
      getStandardGradeJson,
      getAttributeStructure,
      table_data,
      standard_data,
      handleCheckChange,
      rangeValidator,
      validateInput
    };
  },
});
